export class MediaDevice {
    static readonly ScreenCapture = new MediaDevice(
        'screen_capture',
        'Screen Capture',
        null,
    );

    id: string;
    label: string;
    info: MediaDeviceInfo | IpCameraInfo | null;

    get isAudioDevice(): boolean {
        return (
            this.isIpCamera ||
            (this.info as MediaDeviceInfo)?.kind === 'audioinput'
        );
    }

    get isIpCamera(): boolean {
        return !!this.info && 'url' in this.info;
    }

    get isScreenCapture(): boolean {
        return this === MediaDevice.ScreenCapture;
    }

    get isVideoDevice(): boolean {
        return (
            this.isIpCamera ||
            this === MediaDevice.ScreenCapture ||
            (this.info as MediaDeviceInfo)?.kind === 'videoinput'
        );
    }

    constructor(
        id: string,
        label: string,
        info: MediaDeviceInfo | IpCameraInfo | null,
    ) {
        this.id = id;
        this.label = label;
        this.info = info;
    }

    static fromIpCamera(info: IpCameraInfo): MediaDevice {
        return new MediaDevice(info.id, info.label, info);
    }

    static fromUsbDevice(info: MediaDeviceInfo): MediaDevice {
        return new MediaDevice(info.deviceId, info.label, info);
    }
}

export interface IpCameraInfo {
    id: string;
    label: string;
    url: string;
    username?: string;
    password?: string;
}

export interface SelectedMediaDevices {
    audioDevice: MediaDevice | null;
    videoDevice: MediaDevice | null;
}

export interface SelectedMediaDeviceIds {
    audioDeviceId: string | null;
    videoDeviceId: string | null;
}
