/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import {
    ConsentState,
    DealershipForRecordingModel,
    DealRecordingListModel,
    DealRecordingStatus,
    DealType,
    FindDealModel,
    IDealershipForRecordingModel,
    SaleType,
    StartRecordingCommand,
} from 'src/app/modules/shared/service-proxies/service-proxies';
import {
    PascalCaseToSnakeCase,
    SnakeCaseToPascalCase,
    SpacesToPascalCase,
} from 'src/app/modules/shared/utils/string-utils';
import { environment } from 'src/environments/environment';

export interface AbortUploadRequest {
    deal_transaction_id: number;
}

export interface AddLogEntryRequest {
    deal_transaction_id: number;
    description: string;
}

export interface GetAwsCredentialsResponse {
    identity_id: string;
    token: string;
    expiration: string;
}

export interface FindDealRequest {
    dealership_id: number;
    customer_first_name: string;
    customer_last_name: string;
    stock_number?: string;
}

export interface FindDealResponse {
    deal_id?: number;
    sale_type?: string;
    deal_type?: string;
    related_deal_transactions?: string[];
}

export interface RecordingDealInfo {
    dealerships: DealershipListItem[];
    deal_types: NameValuePair[];
    sale_types: NameValuePair[];
}

export interface DealershipListItem {
    id: number;
    name: string;
    consent_state: string;
    video_recording_timeout: number;
}

export interface DealTransaction {
    id: number;
    video_url: string;
    video_thumbnail: string;
    customer: string;
    manager: string;
    deal_number: number;
    date: string;
    deal_type: string;
    video_duration: string;
    customer_present: boolean;
    stock_number: string;
    dealership: string;
    status: string;
    video_upload_error: string | null;
}

export interface NameValuePair {
    name: string;
    value: string;
}

export interface StartRecordingRequest {
    selected_video_device: string;
    selected_audio_device: string;
    dealership_id: number;
    customer_first_name: string;
    customer_last_name: string;
    stock_number: string;
    sale_type: string;
    deal_type: string;
}

export interface StartRecordingResponse {
    deal_transaction_id: number;
}

export interface StopRecordingRequest {
    deal_transaction_id: number;
    reason: string;
}

export interface UploadCompletedRequest {
    deal_transaction_id: number;
    duration: number;
    video_s3_bucket?: string;
    video_s3_region?: string;
    video_s3_object_key?: string;
}

export interface VerifyUserResponse {
    token: string;
}

@Injectable({ providedIn: 'root' })
export class RubyApiProxyService {
    static readonly baseApiUrl = `${environment.rubyPortalBaseUrl}/api/v1`;
    static readonly abortUploadUrl = `${RubyApiProxyService.baseApiUrl}/recordings/abort`;
    static readonly addLogEntryUrl = `${RubyApiProxyService.baseApiUrl}/recordings/add_log_entry`;
    static readonly findDealUrl = `${RubyApiProxyService.baseApiUrl}/recordings/find_deal`;
    static readonly getAwsCredentialsUrl = `${RubyApiProxyService.baseApiUrl}/auth/aws`;
    static readonly logoutUrl = `${RubyApiProxyService.baseApiUrl}/auth/logout`;
    static readonly recordingsDealInfoUrl = `${RubyApiProxyService.baseApiUrl}/recordings`;
    static readonly startRecordingUrl = `${RubyApiProxyService.baseApiUrl}/recordings/start`;
    static readonly stopRecordingUrl = `${RubyApiProxyService.baseApiUrl}/recordings/stop`;
    static readonly uploadCompletedUrl = `${RubyApiProxyService.baseApiUrl}/recordings/upload_completed`;
    static readonly verifyUserUrl = `${RubyApiProxyService.baseApiUrl}/auth/verify`;
    static readonly dealTransactionsUrl = `${RubyApiProxyService.baseApiUrl}/deal_transactions`;

    constructor(private httpClient: HttpClient) {}

    async abortUpload(request: AbortUploadRequest): Promise<void> {
        return await firstValueFrom(
            this.httpClient.post<void>(
                RubyApiProxyService.abortUploadUrl,
                request,
            ),
        );
    }

    async addLogEntry(request: AddLogEntryRequest): Promise<void> {
        return await firstValueFrom(
            this.httpClient.post<void>(
                RubyApiProxyService.addLogEntryUrl,
                request,
            ),
        );
    }

    async findDeal(request: FindDealRequest): Promise<FindDealModel> {
        const result = await firstValueFrom(
            this.httpClient.post<FindDealResponse>(
                RubyApiProxyService.findDealUrl,
                request,
            ),
        );

        return FindDealModel.fromJS({
            id: result.deal_id ?? 0,
            type: DealType[
                SnakeCaseToPascalCase(
                    result.deal_type ?? null,
                ) as keyof typeof DealType
            ],
            saleType:
                SaleType[
                    SnakeCaseToPascalCase(
                        result.sale_type ?? null,
                    ) as keyof typeof SaleType
                ],
            recordingDates:
                result.related_deal_transactions?.map((d) =>
                    DateTime.fromFormat(d, 'MM/dd/yyyy'),
                ) ?? [],
        });
    }

    async getAwsCredentials(): Promise<GetAwsCredentialsResponse> {
        return await firstValueFrom(
            this.httpClient.get<GetAwsCredentialsResponse>(
                RubyApiProxyService.getAwsCredentialsUrl,
            ),
        );
    }

    async getRecordingDealershipInfo(): Promise<DealershipForRecordingModel[]> {
        const res = await firstValueFrom(
            this.httpClient.get<RecordingDealInfo>(
                RubyApiProxyService.recordingsDealInfoUrl,
            ),
        );

        return res.dealerships.map(
            (d) =>
                new DealershipForRecordingModel({
                    id: d.id,
                    name: d.name,
                    consentState:
                        d.consent_state === '2_party'
                            ? ConsentState.TwoParty
                            : ConsentState.OneParty,
                    recordingTimeout: d.video_recording_timeout,
                    displayIpCameraInstallerLink: false,
                } as IDealershipForRecordingModel),
        );
    }

    async getDealRecordings(limit?: number): Promise<DealRecordingListModel[]> {
        const limitParam = limit ? `?limit=${limit}` : '';
        const recordings = await firstValueFrom(
            this.httpClient.get<DealTransaction[]>(
                `${RubyApiProxyService.dealTransactionsUrl}${limitParam}`,
            ),
        );
        return recordings.map((r) => this.mapDealTransaction(r));
    }

    async getDealTransaction(id: number): Promise<DealRecordingListModel> {
        const recording = await firstValueFrom(
            this.httpClient.get<DealTransaction>(
                `${RubyApiProxyService.dealTransactionsUrl}/${id}`,
            ),
        );

        return this.mapDealTransaction(recording);
    }

    private mapDealTransaction(
        recording: DealTransaction,
    ): DealRecordingListModel {
        return DealRecordingListModel.fromJS({
            id: recording.id,
            customerName: recording.customer,
            dealNumber: recording.deal_number,
            dealershipName: recording.dealership,
            dealType:
                DealType[
                    SpacesToPascalCase(
                        recording.deal_type ?? null,
                    ) as keyof typeof DealType
                ],
            startedAt: DateTime.fromJSDate(
                new Date(recording.date.split('(')[0]),
            ),
            status: this.mapDealTransactionStatus(recording),
            stockNumber: recording.stock_number,
            thumbnailUrl: recording.video_thumbnail,
            userName: recording.manager,
            videoDuration: recording.video_duration,
            videoUrl: recording.video_url,
        });
    }

    private mapDealTransactionStatus(
        recording: DealTransaction,
    ): DealRecordingStatus {
        if (recording.video_upload_error) {
            return DealRecordingStatus.UploadAborted;
        }

        switch (recording.status) {
            case 'in_progress':
                return DealRecordingStatus.InProgress;
            case 'stopped':
                return DealRecordingStatus.Stopped;
            case 'uploading':
                return DealRecordingStatus.Uploading;
            case 'finished':
                return DealRecordingStatus.Uploaded;
            default:
                return DealRecordingStatus.InProgress;
        }
    }

    async logout(): Promise<void> {
        await firstValueFrom(
            this.httpClient.delete(RubyApiProxyService.logoutUrl, {
                withCredentials: true,
            }),
        );
    }

    async startRecording(
        request: StartRecordingCommand,
    ): Promise<StartRecordingResponse> {
        const data: StartRecordingRequest = {
            selected_video_device:
                request.selectedDeviceInfo.videoDeviceName ?? '',
            selected_audio_device:
                request.selectedDeviceInfo.audioDeviceName ?? '',
            dealership_id: request.dealershipId ?? 0,
            customer_first_name: request.customerFirstName ?? '',
            customer_last_name: request.customerLastName ?? '',
            stock_number: request.stockNumber ?? '',
            sale_type: PascalCaseToSnakeCase(SaleType[request.saleType]),
            deal_type: PascalCaseToSnakeCase(DealType[request.dealType]),
        };
        return await firstValueFrom(
            this.httpClient.post<StartRecordingResponse>(
                RubyApiProxyService.startRecordingUrl,
                data,
            ),
        );
    }

    async stopRecording(request: StopRecordingRequest): Promise<void> {
        return await firstValueFrom(
            this.httpClient.post<void>(
                RubyApiProxyService.stopRecordingUrl,
                request,
            ),
        );
    }

    async uploadCompleted(request: UploadCompletedRequest): Promise<void> {
        return await firstValueFrom(
            this.httpClient.post<void>(
                RubyApiProxyService.uploadCompletedUrl,
                request,
            ),
        );
    }

    async verifyUser(): Promise<VerifyUserResponse> {
        return await firstValueFrom(
            this.httpClient.get<VerifyUserResponse>(
                RubyApiProxyService.verifyUserUrl,
                { withCredentials: true },
            ),
        );
    }
}
