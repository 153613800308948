import { Injectable, OnDestroy } from '@angular/core';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    debounceTime,
    firstValueFrom,
    from,
    map,
    takeUntil,
    withLatestFrom,
} from 'rxjs';
import {
    IpCameraInfo,
    MediaDevice,
    SelectedMediaDevices,
} from '../models/media-device';
import { MediaSource } from '../models/media-source';
import { WindowsWorkerService } from './windows-worker.service';
import { DataStorageService } from './data-storage.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';

@Injectable({
    providedIn: 'root',
})
export class CameraService implements OnDestroy {
    public static readonly FPS = 30;
    private static readonly IP_STREAM_URL = 'http://localhost:4502';

    private readonly mediaDevices = new BehaviorSubject<MediaDevice[]>([]);
    private readonly selectedMediaDevices =
        new BehaviorSubject<SelectedMediaDevices>({
            audioDevice: null,
            videoDevice: null,
        });
    readonly selectedMediaDevices$ = this.selectedMediaDevices.asObservable();
    readonly audioDevices$ = combineLatest([
        this.mediaDevices,
        this.selectedMediaDevices,
    ]).pipe(
        map(([devices, selectedDevices]) => {
            const videoDeviceIsIpCamera =
                selectedDevices.videoDevice?.isIpCamera ?? false;
            return videoDeviceIsIpCamera
                ? devices.filter(
                      (d) =>
                          d.isAudioDevice &&
                          (!d.isIpCamera ||
                              d.id === selectedDevices.videoDevice?.id),
                  )
                : devices.filter((d) => d.isAudioDevice && !d.isIpCamera);
        }),
    );
    readonly videoDevices$ = this.mediaDevices.pipe(
        map((devices) => devices.filter((d) => d.isVideoDevice)),
    );
    private readonly source = new BehaviorSubject<MediaSource | null>(null);
    readonly source$ = this.source.asObservable();

    private readonly unsubscribeSubject$ = new Subject<void>();

    private permissionsGranted = false;

    constructor(
        private dataStorageService: DataStorageService,
        private featureFlagsService: FeatureFlagsService,
        private windowsWorkerService: WindowsWorkerService,
    ) {
        navigator.mediaDevices.ondevicechange = (): void =>
            void this.loadDevices();

        this.windowsWorkerService.isInstalled$
            .pipe(takeUntil(this.unsubscribeSubject$))
            .subscribe(() => void this.loadDevices());

        this.mediaDevices
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                debounceTime(250),
                withLatestFrom(
                    this.selectedMediaDevices$,
                    from(this.dataStorageService.getSelectedMediaDeviceIds()),
                ),
            )
            .subscribe(([availableDevices, selectedDevices, storedDevices]) => {
                const audioDevices = availableDevices.filter(
                    (d) => d.isAudioDevice,
                );
                const videoDevices = availableDevices.filter(
                    (d) => d.isVideoDevice,
                );

                if (
                    //Need to check label and ID since USB device labels change depending on which
                    // one the OS prefers as the default when devices are added and removed
                    audioDevices.find(
                        (d) =>
                            d.id === selectedDevices.audioDevice?.id &&
                            d.label === selectedDevices.audioDevice?.label,
                    ) &&
                    videoDevices.find(
                        (d) =>
                            d.id === selectedDevices.videoDevice?.id &&
                            d.label === selectedDevices.videoDevice?.label,
                    )
                ) {
                    return;
                }

                const audioDevice =
                    audioDevices.find(
                        (d) => d.id === storedDevices.audioDeviceId,
                    ) ??
                    audioDevices[0] ??
                    null;
                const videoDevice =
                    videoDevices.find(
                        (d) => d.id === storedDevices.videoDeviceId,
                    ) ??
                    videoDevices[0] ??
                    null;

                void this.selectDevices(audioDevice, videoDevice);
            });

        this.selectedMediaDevices$
            .pipe(takeUntil(this.unsubscribeSubject$))
            .subscribe((devices) => void this.startStream(devices));
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }

    async shouldRequestPermission(): Promise<boolean> {
        const cameraPermission = await navigator.permissions.query({
            name: 'camera',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any);
        const microphonePermission = await navigator.permissions.query({
            name: 'microphone',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any);

        this.permissionsGranted =
            cameraPermission.state === 'granted' &&
            microphonePermission.state === 'granted';

        const usbDevices = await this.getUsbDevices();

        return !this.permissionsGranted && usbDevices.length > 0;
    }

    async requestPermission(): Promise<void> {
        try {
            // If permission has not been granted, this will block until the user acknowledges the prompt
            await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });
            this.permissionsGranted = true;
        } catch (err) {
            console.error(err);
        }
    }

    async loadDevices(): Promise<MediaDevice[]> {
        const devices = [];
        if (this.permissionsGranted) {
            devices.push(...(await this.getUsbDevices()));
        }

        const ipCameraSupported = await firstValueFrom(
            this.windowsWorkerService.isInstalled$,
        );
        if (ipCameraSupported) {
            const ipCameras = await this.dataStorageService.getIpCameras();
            for (const ipCamera of ipCameras) {
                devices.push(MediaDevice.fromIpCamera(ipCamera));
            }
        }

        if (this.featureFlagsService.allowScreenCapture) {
            devices.push(MediaDevice.ScreenCapture);
        }

        devices.sort((a, b) => a.label.localeCompare(b.label));
        this.mediaDevices.next(devices);

        return [...devices];
    }

    private async getUsbDevices(): Promise<MediaDevice[]> {
        return (await navigator.mediaDevices.enumerateDevices())
            .filter((d) => !d.label.includes('VisioForge'))
            .map((d) => MediaDevice.fromUsbDevice(d));
    }

    async selectDevices(
        audioDevice: MediaDevice | null,
        videoDevice: MediaDevice | null,
    ): Promise<void> {
        if (!videoDevice?.isIpCamera && audioDevice?.isIpCamera) {
            audioDevice =
                (await firstValueFrom(this.audioDevices$)).find(
                    (d) => !d.isIpCamera,
                ) ?? null;
        } else if (
            videoDevice?.isIpCamera &&
            audioDevice?.isIpCamera &&
            videoDevice.id !== audioDevice.id
        ) {
            audioDevice = videoDevice;
        }

        this.selectedMediaDevices.next({
            audioDevice,
            videoDevice,
        });

        await this.dataStorageService.storeSelectedMediaDevices({
            audioDeviceId: audioDevice?.id ?? null,
            videoDeviceId: videoDevice?.id ?? null,
        });
    }

    private async startStream(devices: SelectedMediaDevices): Promise<void> {
        const oldSource = this.source.value;

        if (!devices.audioDevice || !devices.videoDevice) {
            this.source.next(null);
        } else if (devices.videoDevice.isIpCamera) {
            const info = devices.videoDevice.info as IpCameraInfo;
            await this.windowsWorkerService.configureIpCamera(info);
            this.source.next(new MediaSource(CameraService.IP_STREAM_URL));
        } else if (devices.videoDevice.isScreenCapture) {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                audio: { deviceId: devices.audioDevice?.id },
                video: {
                    frameRate: CameraService.FPS,
                },
            });
            this.source.next(new MediaSource(stream));
        } else {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: devices.audioDevice?.id },
                video: {
                    deviceId: devices.videoDevice?.id,
                    aspectRatio: { ideal: 1.33, max: 1.77 }, //ideal = 4:3 (SD), max = 16:9 (HD)
                    frameRate: CameraService.FPS,
                    width: { ideal: 640, max: 960 },
                },
            });
            this.source.next(new MediaSource(stream));
        }

        if (oldSource?.isMediaStream) {
            oldSource.mediaStream.getTracks().forEach((t) => t.stop());
        }
    }
}
