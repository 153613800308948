import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {
    DbConfig,
    IpCamerasStore,
    PendingUploadsStore,
    SelectedMediaDevicesStore,
} from '../config/db-config';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CompletedPart } from '@aws-sdk/client-s3';
import { IpCameraInfo, SelectedMediaDeviceIds } from '../models/media-device';
import { StopReason } from '../../shared/service-proxies/service-proxies';
import { TelemetryService } from 'src/app/core/services/telemetry.service';

export interface PendingUpload {
    id: number;
    dealRecordingId: number;
    customerName: string;
    isWindowsServiceRecording: boolean;
    uploadId?: string;
    completedParts: CompletedPart[];
    pendingDataChunks: Blob[];
    videoThumbnail?: string;
    duration?: number;
    stopReason?: StopReason;
    recordingStopped: boolean;
    uploadStarted: boolean;
    videoObjectKey: string;
    thumbnailObjectKey: string;
    videoUploadCompleted: boolean;
    thumbnailUploadCompleted: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class DataStorageService {
    private readonly databaseDeletedKey = 'databaseDeleted';
    private readonly storageUsed = new BehaviorSubject<number>(0);
    readonly storageUsed$ = this.storageUsed.asObservable();

    constructor(
        private dbService: NgxIndexedDBService,
        private telemetryService: TelemetryService,
    ) {}

    async assertDatabaseIntegrity(): Promise<void> {
        const databaseDeleted = localStorage.getItem(this.databaseDeletedKey);
        for (const storeMeta of DbConfig.objectStoresMeta) {
            try {
                await firstValueFrom(this.dbService.count(storeMeta.store));
            } catch (err: unknown) {
                const errMsg = (err as object).toString();
                if (
                    errMsg.includes('objectStore does not exist') &&
                    !databaseDeleted
                ) {
                    this.telemetryService.logException(new Error(errMsg));
                    await firstValueFrom(this.dbService.deleteDatabase());
                    localStorage.setItem(this.databaseDeletedKey, 'true');
                    location.reload();
                    return;
                }
            }
        }

        localStorage.removeItem(this.databaseDeletedKey);
    }

    async getIpCameras(): Promise<IpCameraInfo[]> {
        return await firstValueFrom(this.dbService.getAll(IpCamerasStore));
    }

    async upsertIpCamera(info: IpCameraInfo): Promise<void> {
        await firstValueFrom(this.dbService.update(IpCamerasStore, info));
    }

    async deleteIpCamera(id: string): Promise<void> {
        await firstValueFrom(this.dbService.delete(IpCamerasStore, id));
    }

    async clearPendingUpload(pendingUpload: PendingUpload): Promise<void> {
        await firstValueFrom(
            this.dbService.delete(PendingUploadsStore, pendingUpload.id),
        );
        await this.updateRemainingStorage();
        console.log(`Pending upload '${pendingUpload.id}' cleared.`);
    }

    async createPendingUpload(
        dealRecordingId: number,
        customerName: string,
        isWindowsServiceRecording: boolean,
        videoObjectKey: string,
        thumbnailObjectKey: string,
    ): Promise<PendingUpload> {
        const pendingUpload: PendingUpload = {
            id: dealRecordingId,
            dealRecordingId,
            customerName,
            isWindowsServiceRecording,
            completedParts: [],
            pendingDataChunks: [],
            recordingStopped: false,
            uploadStarted: false,
            videoObjectKey,
            thumbnailObjectKey,
            videoUploadCompleted: false,
            thumbnailUploadCompleted: false,
        };
        await this.storePendingUpload(pendingUpload);
        return pendingUpload;
    }

    async getPendingUploads(): Promise<PendingUpload[]> {
        const pendingUploads = await firstValueFrom(
            this.dbService.getAll<PendingUpload>(PendingUploadsStore),
        );
        return pendingUploads.sort(
            (a, b) => a.dealRecordingId - b.dealRecordingId,
        );
    }

    async getSelectedMediaDeviceIds(): Promise<SelectedMediaDeviceIds> {
        const devices = (
            await firstValueFrom(
                this.dbService.getAll<SelectedMediaDeviceIds>(
                    SelectedMediaDevicesStore,
                ),
            )
        )[0];

        return devices ?? { audioDeviceId: null, videoDeviceId: null };
    }

    async storePendingUpload(pendingUpload: PendingUpload): Promise<void> {
        await firstValueFrom(
            this.dbService.update(PendingUploadsStore, pendingUpload),
        );
        await this.updateRemainingStorage();
        const totalMb =
            new Blob(pendingUpload.pendingDataChunks).size / 1000000;
        console.log(
            `Pending upload '${pendingUpload.id}' stored in browser with ${totalMb} MB of data.`,
        );
    }

    async storeSelectedMediaDevices(
        devices: SelectedMediaDeviceIds,
    ): Promise<void> {
        await firstValueFrom(this.dbService.clear(SelectedMediaDevicesStore));
        await firstValueFrom(
            this.dbService.add(SelectedMediaDevicesStore, devices),
        );
    }

    private async updateRemainingStorage(): Promise<void> {
        const storage = await navigator.storage.estimate();
        if (!storage.quota || !storage.usage) {
            console.warn('Could not determine indexedDB storage estimate.');
            return;
        }

        const percent = parseFloat((storage.usage / storage.quota).toFixed(8));
        this.storageUsed.next(percent);
    }
}
