import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { RefreshService } from 'src/app/core/services/refresh.service';

@Component({
    templateUrl: './main-layout.component.html',
    styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements OnInit, OnDestroy {
    private readonly unsubscribeSubject$ = new Subject<void>();
    sidebarShrinked: boolean = false;
    menuToggled: boolean = false;
    menuClass: string = '';
    accountMenuItems: MenuItem[] = [
        {
            label: 'My profile',
            routerLink: '../main/accounts/profile',
            icon: 'pi pi-user',
        },
        {
            label: 'Log out',
            icon: 'pi pi-sign-out',
            command: (): void => void this.authService.logout(),
        },
    ];

    constructor(
        public refreshService: RefreshService,
        public authService: AuthService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.router.events
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe(() => {
                this.menuToggled = false;
                this.animateMenu();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }

    toggleMenu(): void {
        this.menuToggled = !this.menuToggled;
        this.animateMenu();
    }

    animateMenu(): void {
        if (this.menuToggled) {
            this.menuClass = 'show';
        } else {
            this.menuClass = 'show  hiding';
            setTimeout(() => {
                this.menuClass = '';
            }, 300);
        }
    }
}
