import { Pipe, PipeTransform } from '@angular/core';
import { Int32NameValuePair } from '../service-proxies/service-proxies';

@Pipe({ name: 'consentStateToNameValuePairs' })
export class ConstentStateToNameValuePairsPipe implements PipeTransform {
    transform(value: object): Int32NameValuePair[] {
        return Object.keys(value)
            .filter((key) => isNaN(parseInt(key)))
            .map((key) => {
                const period = value[key as keyof object];

                return Int32NameValuePair.fromJS({
                    name: period + '-Party',
                    value: value[key as keyof object],
                });
            });
    }
}
