import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AwsAuthService } from './aws-auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { IpCameraInfo, MediaDevice } from '../models/media-device';
import { AwsS3Service } from './aws-s3.service';
import { WindowsServiceBaseUrl } from '../config/windows-worker-config';
import { SemVer } from 'semver';

export interface WindowsServiceInfo {
    version: string;
    machineName: string;
}

@Injectable({
    providedIn: 'root',
})
export class WindowsWorkerService implements OnDestroy {
    private static readonly configureUrl = `${WindowsServiceBaseUrl}/configure/`;
    private static readonly getInfoUrl = `${WindowsServiceBaseUrl}/get-info/`;
    private static readonly startRecordingUrl = `${WindowsServiceBaseUrl}/start-recording/`;
    private static readonly stopRecordingUrl = `${WindowsServiceBaseUrl}/stop-recording/`;
    private static readonly uploadFileUrl = `${WindowsServiceBaseUrl}/upload-file/`;
    private static readonly uploadUrl = `${WindowsServiceBaseUrl}/upload/`;

    public static readonly VIDEO_EXTENSION = 'mp4';

    private readonly isInstalled = new BehaviorSubject<boolean | null>(null);
    readonly isInstalled$ = this.isInstalled.asObservable();
    private readonly serviceInfo =
        new BehaviorSubject<WindowsServiceInfo | null>(null);
    readonly serviceInfo$ = this.serviceInfo.asObservable();

    private pollingWorker: Worker | undefined;

    constructor(
        private awsAuthService: AwsAuthService,
        private awsS3Service: AwsS3Service,
        private httpClient: HttpClient,
    ) {}

    ngOnDestroy(): void {
        if (this.pollingWorker) {
            this.pollingWorker.postMessage('stop');
            this.pollingWorker.terminate();
        }
    }

    async initialize(): Promise<void> {
        const res = await this.getInfo();
        this.isInstalled.next(!!res);
        this.serviceInfo.next(res);
        if (!res) {
            return;
        }

        await this.stopRecording();
        await this.uploadPendingFiles();
        if (this.isIpRecordingSupported()) {
            this.pollingWorker = new Worker(
                new URL(
                    '../workers/windows-service-polling.worker',
                    import.meta.url,
                ),
            );
        }
    }

    isIpRecordingSupported(): boolean {
        return typeof Worker !== 'undefined';
    }

    async configureIpCamera(info: IpCameraInfo): Promise<void> {
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.configureUrl, {
                url: info.url,
                username: info.username,
                password: info.password,
            }),
        );
    }

    private async getInfo(): Promise<WindowsServiceInfo | null> {
        try {
            const res = await firstValueFrom(
                this.httpClient.post<WindowsServiceInfo>(
                    WindowsWorkerService.getInfoUrl,
                    {},
                ),
            );
            if (res) {
                //this is only needed for Windows service versions prior to 1.0.1
                res.version = this.sanitizeVersion(res.version);
            }

            return res;
        } catch {
            return null;
        }
    }

    private sanitizeVersion(version: string): string {
        const parts = version.split('.');
        if (parts.length > 3) {
            version = parts.slice(0, 3).join('.');
        }

        return version;
    }

    async startRecording(
        dealRecordingId: number,
        selectedAudioDevice: MediaDevice | null,
    ): Promise<void> {
        const objectKey = this.awsS3Service.getObjectKey(
            dealRecordingId,
            WindowsWorkerService.VIDEO_EXTENSION,
        );
        const audioDevice =
            !selectedAudioDevice?.info || selectedAudioDevice.isIpCamera
                ? null
                : selectedAudioDevice.info.label;
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.startRecordingUrl, {
                bucket: environment.aws.s3Bucket,
                objectKey,
                audioDevice,
            }),
        );
        this.pollingWorker?.postMessage('start');
    }

    async stopRecording(): Promise<void> {
        this.pollingWorker?.postMessage('stop');
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.stopRecordingUrl, {}),
        );
    }

    async uploadFile(bucket: string, objectKey: string): Promise<void> {
        const info = await firstValueFrom(this.serviceInfo$);
        if (info && new SemVer(info.version) < new SemVer('1.0.1')) {
            await this.uploadPendingFiles();
            return;
        }

        const credentials = await this.awsAuthService.getCredentials();
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.uploadFileUrl, {
                awsIdentityId: credentials.identityId,
                awsCognitoToken: credentials.token,
                awsRegion: environment.aws.region,
                bucket,
                objectKey,
            }),
        );
    }

    async uploadPendingFiles(): Promise<void> {
        const credentials = await this.awsAuthService.getCredentials();
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.uploadUrl, {
                awsIdentityId: credentials.identityId,
                awsCognitoToken: credentials.token,
                awsRegion: environment.aws.region,
            }),
        );
    }
}
