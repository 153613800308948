import { Pipe, PipeTransform } from '@angular/core';
import { Int32NameValuePair } from '../service-proxies/service-proxies';
import { Duration } from 'luxon';

@Pipe({ name: 'retentionPeriodToNameValuePairs' })
export class RetentionPeriodToNameValuePairsPipe implements PipeTransform {
    transform(value: object): Int32NameValuePair[] {
        return Object.keys(value)
            .filter((key) => isNaN(parseInt(key)))
            .map((key) => {
                const period = value[key as keyof object];
                const label =
                    period <= 365
                        ? Duration.fromObject({ days: period }).as('days') + ' Days'
                        : Duration.fromObject({ days: period }).as('years') + ' Years';
                return Int32NameValuePair.fromJS({
                    name: label,
                    value: value[key as keyof object],
                });
            });
    }
}
