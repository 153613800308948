import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { RecordingDurationPipe } from './pipes/recording-duration.pipe';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { LegacyLayoutComponent } from './layouts/legacy-layout/legacy-layout.component';
import { ButtonModule } from 'primeng/button';
import {
    AuthServiceProxy,
    DealerGroupsServiceProxy,
    DealershipsServiceProxy,
    FeatureFlagsServiceProxy,
    HealthCheckServiceProxy,
    DealerNodesServiceProxy,
    RegionsServiceProxy,
    UsersServiceProxy,
    DealRecordingsServiceProxy,
    DealsServiceProxy,
} from './service-proxies/service-proxies';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { SideBarMenuComponent } from './components/side-bar-menu/side-bar-menu.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FormattedGuidPipe } from './pipes/formatted-guid.pipe';
import { UpdateMessageComponent } from './components/update-message/update-message.component';
import { FilterInputComponent } from './components/filter-input/filter-input.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { FilterMultiselectComponent } from './components/filter-multiselect/filter-multiselect.component';
import { FilterTreeSelectComponent } from './components/filter-tree-select/filter-tree-select.component';
import { TreeSelectModule } from 'primeng/treeselect';
import { FlattenStringListPipe } from './pipes/flatten-string-list';
import { EnumToNameValuePairsPipe } from './pipes/enum-to-name-value-pairs.pipe';
import { TimeSpanPipe } from './pipes/timespan.pipe';
import { InputMaskModule } from 'primeng/inputmask';
import { RetentionPeriodToNameValuePairsPipe } from './pipes/retention-period-to-name-value-pairs.pipe';
import { RequestEmailChangeModalComponent } from './components/request-email-change-modal/request-email-change-modal.component';
import { RecordingTimeoutToNameValuePairsPipe } from './pipes/recording-timeout-to-name-value-pairs.pipe';
import { ConstentStateToNameValuePairsPipe } from './pipes/consent-state-to-name-value-pairs.pipe';

const globalImports = [
    CommonModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    ToastModule,
    TreeSelectModule,
    MultiSelectModule,
    MenuModule,
];

@NgModule({
    declarations: [
        AuthLayoutComponent,
        ConstentStateToNameValuePairsPipe,
        EnumToNameValuePairsPipe,
        FilterInputComponent,
        FilterMultiselectComponent,
        FilterTreeSelectComponent,
        FlattenStringListPipe,
        FormattedGuidPipe,
        LegacyLayoutComponent,
        LoaderComponent,
        MainLayoutComponent,
        RecordingDurationPipe,
        RecordingTimeoutToNameValuePairsPipe,
        RetentionPeriodToNameValuePairsPipe,
        SideBarMenuComponent,
        TimeSpanPipe,
        UpdateMessageComponent,
        ValidationMessageComponent,
        RequestEmailChangeModalComponent,
    ],
    imports: [
        ...globalImports,
        ButtonModule,
        MenubarModule,
        MenuModule,
        PaginatorModule,
        MultiSelectModule,
        InputMaskModule,
    ],
    exports: [
        ...globalImports,
        AuthLayoutComponent,
        ConstentStateToNameValuePairsPipe,
        EnumToNameValuePairsPipe,
        FilterInputComponent,
        FilterMultiselectComponent,
        FilterTreeSelectComponent,
        FlattenStringListPipe,
        FormattedGuidPipe,
        LegacyLayoutComponent,
        LoaderComponent,
        MainLayoutComponent,
        RecordingDurationPipe,
        RecordingTimeoutToNameValuePairsPipe,
        RetentionPeriodToNameValuePairsPipe,
        SideBarMenuComponent,
        TimeSpanPipe,
        ValidationMessageComponent,
        RequestEmailChangeModalComponent,
    ],
    providers: [
        AuthServiceProxy,
        DealerGroupsServiceProxy,
        DealerNodesServiceProxy,
        DealershipsServiceProxy,
        DealRecordingsServiceProxy,
        DealsServiceProxy,
        FeatureFlagsServiceProxy,
        HealthCheckServiceProxy,
        RegionsServiceProxy,
        UsersServiceProxy,
    ],
})
export class SharedModule { }
