/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { LegacyLayoutComponent } from './modules/shared/layouts/legacy-layout/legacy-layout.component';
import { AuthLayoutComponent } from './modules/shared/layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './modules/shared/layouts/main-layout/main-layout.component';
import { legacyAppGuard } from './core/guards/legacy-app.guard';
import { newAppGuard } from './core/guards/new-app.guard';
import { permissionGuard } from './core/guards/permission.guard';
import { mfaSetupGuard } from './core/guards/mfa-setup.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'app',
                component: LegacyLayoutComponent,
                canActivate: [legacyAppGuard(), authGuard()],
                children: [
                    {
                        path: 'help',
                        loadChildren: () =>
                            import('./modules/help/help.module').then(
                                (m) => m.HelpModule,
                            ),
                    },
                    {
                        path: 'record',
                        canActivate: [permissionGuard('recording')],
                        loadChildren: () =>
                            import('./modules/recording/recording.module').then(
                                (m) => m.LegacyRecordingModule,
                            ),
                    },
                    { path: '', redirectTo: 'record', pathMatch: 'full' },
                    { path: '**', redirectTo: 'record' },
                ],
            },
            {
                path: 'auth',
                component: AuthLayoutComponent,
                canActivate: [newAppGuard()],
                loadChildren: () =>
                    import('./modules/auth/auth.module').then(
                        (m) => m.AuthModule,
                    ),
            },
            {
                path: 'main',
                component: MainLayoutComponent,
                canActivate: [newAppGuard(), authGuard(), mfaSetupGuard(true)],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/main/main.module').then(
                                (m) => m.MainModule,
                            ),
                    },
                    {
                        path: 'help',
                        loadChildren: () =>
                            import('./modules/help/help.module').then(
                                (m) => m.HelpModule,
                            ),
                    },
                    {
                        path: 'recordings',
                        canActivate: [permissionGuard('recording')],
                        loadChildren: () =>
                            import('./modules/recording/recording.module').then(
                                (m) => m.RecordingModule,
                            ),
                    },
                    {
                        path: 'admin',
                        loadChildren: () =>
                            import('./modules/admin/admin.module').then(
                                (m) => m.AdminModule,
                            ),
                    },
                    {
                        path: 'accounts',
                        loadChildren: () =>
                            import('./modules/accounts/accounts.module').then(
                                (m) => m.AccountsModule,
                            ),
                    },
                ],
            },
            { path: '', redirectTo: 'main/home', pathMatch: 'full' },
            { path: '**', redirectTo: 'main/home' },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
