import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FeatureFlagsServiceProxy } from 'src/app/modules/shared/service-proxies/service-proxies';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
    private _allowScreenCapture = false;
    private _useDotNetBackEnd = false;

    get allowScreenCapture(): boolean {
        return this._allowScreenCapture;
    }

    get useDotNetBackEnd(): boolean {
        return this._useDotNetBackEnd;
    }

    constructor(private featureFlagsServiceProxy: FeatureFlagsServiceProxy) {}

    async init(): Promise<void> {
        // Wrapping this in try/catch and swallowing error since .NET back end will not be
        // running for local development with the Ruby portal
        try {
            const res = await firstValueFrom(
                this.featureFlagsServiceProxy.featureFlags(),
            );
            this._allowScreenCapture = res.allowScreenCapture;
            this._useDotNetBackEnd = res.useDotNetBackEnd;
        } catch (err) {
            console.error(
                'Failed to fetch feature flags; using default values. Is your .NET back end running?',
            );
        }
    }
}
