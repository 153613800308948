import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeSpan' })
export class TimeSpanPipe implements PipeTransform {
    transform(value: string | null): string {
        if (!value) return '';

        // Split the TimeSpan string into its components
        const [hours, minutes, seconds] = value.split(':');
        if (!seconds) {
            return value;
        }
        const formattedSeconds = seconds?.split('.')[0]; // Remove milliseconds

        // If hours are 0, display only minutes and seconds
        if (parseInt(hours, 10) === 0) {
            return `${minutes}:${formattedSeconds}`;
        }

        // Otherwise, display hours, minutes, and seconds
        return `${hours}:${minutes}:${formattedSeconds}`;
    }
}
